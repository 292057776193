import { Controller } from '@hotwired/stimulus';
import tippy, { createSingleton } from 'tippy.js';

export default class extends Controller {
  static targets = ['element'];
  static values = { content: String, placement: String };

  connect() {
    import('tippy.js/dist/tippy.css');
    import('tippy.js/themes/material.css');
    import('tippy.js/animations/scale-subtle.css');

    this.defaultOptions = {
      theme: 'material',
      duration: 100,
      animation: 'scale-subtle',
      placement: this.data.get('placement') || this.placementValue || 'auto',
      hideOnClick: true,
      popperOptions: {
        positionFixed: true
      },
      arrow: true,
      zIndex: 999
    };

    this.instance = tippy(
      this.hasElementTarget ? this.elementTargets : this.element,
      {
        content: (reference) =>
          reference.getAttribute('data-content') || this.content,
        ...this.defaultOptions
      }
    );

    if (this.hasElementTarget) {
      this.singleton = createSingleton(this.instance, {
        delay: 0,
        duration: 150,
        moveTransition: 'transform 0.1s ease-out',
        ...this.defaultOptions
      });
    }

    document.addEventListener('modal:open', this.instance.hide);
  }

  get content() {
    return this.data.get('content') || this.contentValue;
  }

  disconnect() {
    if (!this.hasElementTarget) {
      this.instance.destroy();
    }
  }
}
